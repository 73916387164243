import React, { FC } from "react";
import { Box } from "@mui/material";

type IconBubbleProps = {
  side: "left" | "right"
}

export const IconBubble:FC<IconBubbleProps> = ({ side, children }) => {
  return (
    <Box position="absolute"
      margin={1}
      bottom={0}
      left={side == "left" ? 0 : "auto"}
      right={side == "right" ? 0 : "auto"}
      borderRadius={4}
      sx={{
        backgroundColor: "rgba(0,0,0, 0.4)",
        padding: 0.75,
      }}
      >
      {children}
    </Box>
  );
};

export default IconBubble;
