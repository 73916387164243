import React, { FC, useState } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

import theme from "../../../gatsby-theme-material-ui-top-layout/theme";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

// Docs: https://www.npmjs.com/package/react-lazy-load-image-component
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IconBubble from "./IconBubble";
import DetailIcon, { DetailIconConfig } from "./DetailIcon";


type CarResultProps = {
  carData: any
}

export const CarResult:FC<CarResultProps> = ({ carData }) => {
  const [visible, setVisible] = useState(carData.visible);
  carData.setVisible = setVisible;

  // if (!carData.gatsbyImage) { // The car hasn't been processed for images yet since this requires graph
  //   // Fetch all the car images
  //   const graphData = useStaticQuery(graphql`
  //     query images {
  //       defaultPicture: file(relativePath: {eq: "default.png"}) {
  //         childImageSharp {
  //           gatsbyImageData (
  //             width: 330
  //             height: 200
  //             placeholder: DOMINANT_COLOR
  //           )
  //         }
  //       }
  //       allPictures: allFile {
  //         pictures: nodes {
  //           relativePath
  //           childImageSharp {
  //             gatsbyImageData (
  //               width: 330
  //               height: 200
  //               placeholder: DOMINANT_COLOR
  //             )
  //           }
  //         }
  //       }
  //     }
  //   `);
  //   // Find the right car image for this car
  //   for (let picture of graphData.allPictures.pictures) {
  //     if (carData.photo_path === "/" + picture.relativePath) { // Found the matching path
  //       carData.gatsbyImage = getImage(picture);
  //       break; // Bail early on the loop
  //     }
  //   };
  //   if (!carData.gatsbyImage) { // Failed to load an image
  //     carData.gatsbyImage = getImage(graphData.defaultPicture);
  //     //console.log(carData.title + " using defauly image");
  //   }
  // }

  // Configure Left Icons
  var leftIcons:DetailIconConfig[] = [];
  if (carData.filters["Large Family"]) {
    leftIcons.push({
      icon: "👨‍👩‍👧‍👦",
      label: "Great size for a Large Family",
    })
  } else if (carData.filters["Small Family"]) {
    leftIcons.push({
      icon: "👨‍👩‍👧",
      label: "Great size for a Small Family",
    })
  }
  if (carData.filters["Cargo Space"]) {
    leftIcons.push({
      icon: "📦",
      label: "Lots of Cargo Space without having to fold seats",
    })
  }

  // Configure Right Icons
  var rightIcons:DetailIconConfig[] = [];
  if (carData.filters["Electric"]) {
    rightIcons.push({
      icon: "⚡️",
      label: "Electric",
    })
  } else if (carData.filters["Great Fuel Economy"]) {
    rightIcons.push({
      icon: "⛽️",
      label: "Fuel Economey better than 30mpg",
    })
  }
  if (carData.filters["Looks Good"]) {
    rightIcons.push({
      icon: "😎",
      label: "Looks Good",
    })
  }

  return (
    <Grid item xs={6} sm={4} md={4} lg={3} sx={{
      display: visible ? "inline-block" : "none",
      visibility: visible ? "visible" : "hidden",
    }}>
      <Paper
        elevation={2}
        sx={{
          position: "relative",
          margin: "10px",
          overflow: "clip",
          borderRadius: 4,
          maxWidth: 330,
        }}>

        {/* The car image */}
        {/* <GatsbyImage image={carData.gatsbyImage} alt={carData.title} 
          imgStyle={{ // Safari patch for rounded corners
            borderRadius: 16,
          }}
          /> */}
        <LazyLoadImage src={"cars" + carData.photo_path} alt={carData.title}
          threshold={200}
          placeholder={<Box width="100%" height={200}>&nbsp;</Box>}
          width="100%"
          style={{ // Safari patch for rounded corners
            display: "block",
            borderRadius: 16,
            minHeight: 125
          }}
          />

        {/* Static details */}
        <a href={"https://www.ebay.com/sch/i.html?_nkw=" + carData.make.replace(" ", "+") + "+" + carData.model.replace(" ", "+") + "&_sacat=6001&mkcid=1&mkrid=711-53200-19255-0&siteid=0&campid=5338945468&customid=&toolid=10001&mkevt=1"} target="_blank">
          <Box
            top={0}
            position="absolute"
            width="100%" height="100%" 
            >
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="top"
              width="100%"
              marginTop={1}
              >
              <Grid item 
                borderRadius={4}
                sx={{
                  backgroundColor: "rgba(0,0,0, 0.4)",
                  padding: 1,
                }}>
                <Typography variant="subtitle1" component="div" color="#fff">
                  {carData.title}
                </Typography>
              </Grid>
            </Grid>


            {(leftIcons.length > 0) ? 
              <IconBubble side="left">
                {leftIcons.map((icon: any) => (
                <DetailIcon key={icon.label} icon={icon} />
                ))}
              </IconBubble>
              :
              null
            }
            {(rightIcons.length > 0) ? 
              <IconBubble side="right">
                {rightIcons.map((icon: any) => (
                <DetailIcon key={icon.label} icon={icon} />
                ))}
              </IconBubble>
              :
              null
            }
          </Box>
        </a>

        {/* Hover details */}
        {/* <Box position="absolute"
          top={0}
          width="100%" height="100%" 
          padding={2}
          sx={{
            backgroundColor: "rgba(0,0,0, 0.5)",
            color: "#fff",
            transition: "opacity 150ms",
            opacity: 0.01,
            "&:hover": {
              opacity: 1,
            },
            overflow: "clip",
            borderRadius: 4,
          }}>
          {JSON.stringify(carData.car, null, 4)}
        </Box> */}
        
      </Paper>
      {/* </Box> */}
    </Grid>
  );
};

export default CarResult;
