import React, { FC } from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";

import theme from "../../gatsby-theme-material-ui-top-layout/theme";
import CarFilterSelectors from "./filters/CarFilterSelectors";
import CarFilterResults from "./results/CarFilterResults";
import FilterProvider from "../../context/filterContext";


export const CarFilterExperience:FC = ({ children }) => {
  const columnDisplay = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box minWidth={280}>

      <FilterProvider>

        <Grid
        container
        direction={{xs: "column", md: "row"}}
        wrap="nowrap"
        >

          <Grid item xs={4} lg={3} bgcolor="#eee" borderRadius="0 8px 8px 0">
            <CarFilterSelectors />
          </Grid>

          <Grid item xs={8} lg={9}>
            <CarFilterResults />
          </Grid>

        </Grid>

      </FilterProvider>

    </Box>
  );
};

export default CarFilterExperience;
