import React from 'react';
import { FilterContextType, Filter, FilterGroup, FilterAttributeTarget } from '../@types/filter';

export const FilterContext = React.createContext<FilterContextType | null>(null);

export const filterConfig: FilterGroup[] = [
  { // Features
    title: 'Features',
    defaultExpanded: true,
    selection: 'all',
    filters: [
      { id: "Large Family",
        conflictsWithIds: ["Small Family"],
        title: "Large Family 👨‍👩‍👧‍👦",
        description: "6 or more passenger seats, enough room to carry all the kids and maybe some more.",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Passenger Capacity",
              valueMode: "greaterThan",
              value: "5",
            },
          ],
          [
            {
              path: "third_row_option",
              valueMode: "equal",
              value: "True",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Small Family",
        conflictsWithIds: ["Large Family"],
        title: "Small Family 👨‍👩‍👧",
        description: "5 pasenger seats, enough room for a small family and a friend or 2 in a pinch.",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Passenger Capacity",
              valueMode: "equal",
              value: "5",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Great Fuel Economy",
        title: "Sips Fuel ⛽️",
        description: "Better than 30mpg or equivalent efficiency.",
        attributes: [
          [ // Gets good combined MPG
            {
              path: "categories.Fuel Economy.Mileage.Fuel Economy Est-Combined (MPG)",
              valueMode: "greaterThan",
              value: "30",
            },
          ],
          [
            {
              path: "categories.Fuel Economy.Mileage.EPA MPG Equivalent - Combined",
              valueMode: "greaterThan",
              value: "30",
            },
          ],
          [ // or is Electric
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "include",
              value: "Electric",
            },
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "exclude",
              value: "Gas",  // Hybrids mention Gas
            },
          ],
        ],
        enabled: false,
      },
      { id: "Low Maintenence",
        title: "Low Upkeep 🔧",
        description: "Above average time between recommended maintainance.",
        attributes: [
          [ // 5 Seats with enough rear width
            {
              path: "categories.Warranty.Warranty.Maintenance Miles/km",
              valueMode: "greaterThan",
              value: "10000",
            },
            {
              path: "categories.Warranty.Warranty.Maintenance Years",
              valueMode: "greaterThan",
              value: "1",
            },
          ],
          [ // or is Electric
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "include",
              value: "Electric",
            },
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "exclude",
              value: "Gas",  // Hybrids mention Gas
            },
          ],
        ],
        enabled: false,
      },
      { id: "Long Warranty",
        title: "Long Warranty 📜",
        description: "The manufacturer's warranty is more than 4 years.",
        attributes: [
          [
            {
              path: "categories.Warranty.Warranty.Basic Years",
              valueMode: "greaterThan",
              value: "4",
            },
          ],
        ],
        enabled: false,
      },
      { id: "3 Rear Car Seats",
        title: "Fits 3 Child Seats 💺",
        description: "Back seat is wide enough for 3 car seats, or there are 3 rows.",
        attributes: [
          [ // 5 Seats with enough rear width
            {
              path: "categories.Quick Specs.default.Passenger Capacity",
              valueMode: "equal",
              value: "5",
            },
            {
              path: "categories.Dimensions.Interior Dimensions.Second Shoulder Room (in)",
              valueMode: "greaterThan",
              value: "52.5", // 
            },
          ],
          [ // > 5 seats
            {
              path: "categories.Quick Specs.default.Passenger Capacity",
              valueMode: "greaterThan",
              value: "5",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Cargo Space",
        title: "Cargo Space 📦",
        description: "Lots of cargo space without having to fold any seats.",
        attributes: [
          [ // Separate Trunk
            {
              path: "categories.Dimensions.Cargo Area Dimensions.Trunk Volume (ft\u00b3)",
              valueMode: "greaterThan",
              value: "24", // 
            },
          ],
          [ // Folding Rear Set up
            {
              path: "categories.Dimensions.Cargo Area Dimensions.Cargo Volume with Rear Seat Up (ft\u00b3)",
              valueMode: "greaterThan",
              value: "24", // 
            },
          ],
          [ // Storage behind 3rd row
            {
              path: "categories.Dimensions.Cargo Area Dimensions.Cargo Volume to Seat 3 (ft\u00b3)",
              valueMode: "greaterThan",
              value: "24", // 
            },
          ],
        ],
        enabled: false,
      },
      { id: "4 Wheel Drive",
        title: "4 Wheel Drive 🏔",
        description: "4 or All wheel drive.",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Drivetrain",
              valueMode: "include",
              value: "Four Wheel Drive",
            },
          ],
          [
            {
              path: "categories.Quick Specs.default.Drivetrain",
              valueMode: "include",
              value: "All Wheel Drive",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Good Power",
        title: "Good Power",
        icon: "feature_icons/icons8-exercise-48.png",
        description: "A good power to weight ratio for strong acceleration.",
        attributes: [
          [ // Anything with > 600 hp can't be slow regardless of weight
            {
              path: "categories.Performance Specs.Engine.SAE Net Horsepower @ RPM",
              valueMode: "greaterThan",
              value: "600",
            },
          ],
          [
            {
              path: "categories.Performance Specs.Engine.SAE Net Horsepower @ RPM",
              valueMode: "greaterThan",
              value: "500",
            },
            {
              path: "categories.Dimensions.Weight Information.Base Curb Weight (lbs)",
              valueMode: "lessThan",
              value: "6000",
            },
          ],
          [
            {
              path: "categories.Performance Specs.Engine.SAE Net Horsepower @ RPM",
              valueMode: "greaterThan",
              value: "450",
            },
            {
              path: "categories.Dimensions.Weight Information.Base Curb Weight (lbs)",
              valueMode: "lessThan",
              value: "5400",
            },
          ],
          [
            {
              path: "categories.Performance Specs.Engine.SAE Net Horsepower @ RPM",
              valueMode: "greaterThan",
              value: "350",
            },
            {
              path: "categories.Dimensions.Weight Information.Base Curb Weight (lbs)",
              valueMode: "lessThan",
              value: "4200",
            },
          ],
          [
            {
              path: "categories.Performance Specs.Engine.SAE Net Horsepower @ RPM",
              valueMode: "greaterThan",
              value: "300",
            },
            {
              path: "categories.Dimensions.Weight Information.Base Curb Weight (lbs)",
              valueMode: "lessThan",
              value: "3700",
            },
          ],
          [
            {
              path: "categories.Performance Specs.Engine.SAE Net Horsepower @ RPM",
              valueMode: "greaterThan",
              value: "250",
            },
            {
              path: "categories.Dimensions.Weight Information.Base Curb Weight (lbs)",
              valueMode: "lessThan",
              value: "3125",
            },
          ],
          [
            {
              path: "categories.Performance Specs.Engine.SAE Net Horsepower @ RPM",
              valueMode: "greaterThan",
              value: "200",
            },
            {
              path: "categories.Dimensions.Weight Information.Base Curb Weight (lbs)",
              valueMode: "lessThan",
              value: "2500",
            },
          ],
          [ // or is a tesla
            {
              path: "car.make",
              valueMode: "equal",
              value: "tesla",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Great Handling",
        title: "Great Handling",
        icon: "feature_icons/icons8-curvy-street-48.png",
        description: "Vehicles with great handling are perfect for carving up a windy roads.",
        attributes: [
          [
            {
              path: "great_handling",
              valueMode: "equal",
              value: "True",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Looks Good",
        title: "Looks Good 😎",
        description: "Beauty is in the eye of the beholder, but these are generally considered good looking vehicls.",
        attributes: [
          [
            {
              path: "pretty_car",
              valueMode: "equal",
              value: "True",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Manual Transmission",
        title: "Manual Shift ⚙️",
        description: "Has a manual transmission for purist driving enjoyment.",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Transmission",
              valueMode: "include",
              value: "Manual",
            },
          ],
        ],
        enabled: false,
      },
    ]
  },
  { // Motors
    title: 'Motors',
    defaultExpanded: true,
    selection: 'any',
    filters: [
      { id: "Hybrid",
        title: 'Hybrid 🚙',
        description: "Hybrid gas/electric system to propel the vehicle",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "include",
              value: "Gas/Electric",
            },
          ],
          [
            {
              path: "engine_options",
              valueMode: "include",
              value: "hybrid",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Electric",
        title: 'Electric ⚡️',
        description: "Fully electric motor(s) to propel the vehicle",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "include",
              value: "Electric",
            },
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "exclude",
              value: "Gas",  // Hybrids mention Gas
            },
          ],
          [
            {
              path: "engine_options",
              valueMode: "include",
              value: "electric",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Gas",
        title: 'Gas 🚗',
        description: "Gasoline powered internal combustion engines (ICE) to propel the vehicle",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "exclude",
              value: "Diesel",
            },
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "exclude",
              value: "Electric",
            },
          ],
          [
            {
              path: "engine_options",
              valueMode: "include",
              value: "gas",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Diesel",
        title: 'Diesel 🛻',
        description: "Diesel powered internal combustion engines (ICE) to propel the vehicle",
        attributes: [
          [
            {
              path: "categories.Quick Specs.default.Engine",
              valueMode: "include",
              value: "Diesel",
            },
          ],
          [
            {
              path: "engine_options",
              valueMode: "include",
              value: "diesel",
            },
          ],
        ],
        enabled: false,
      },
    ]
  },
  { // Body Style
    title: 'Body Styles',
    defaultExpanded: false,
    selection: 'any',
    filters: [
      { id: "Convertible",
        title: "Convertible",
        icon: "body_styles/icons8-convertible-48.png",
        description: "There's nothing like the wind in your hair.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "convertible",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Coupe",
        title: "Coupes",
        icon: "body_styles/icons8-car-48.png",
        description: "Coupes typically have 2 doors and an attractive look.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "coupe",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Crossover",
        title: "Crossover ",
        icon: "body_styles/icons8-carpool-48.png",
        description: "There's nothing like the wind in your hair.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "crossover",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Hatchback",
        title: "Hatchbacks",
        icon: "body_styles/icons8-fiat-500-48.png",
        description: "Hatchbacks are great for easy cargo access in a smaller vehicle.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "hatchback",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Minivan",
        title: "Minivans",
        icon: "body_styles/icons8-van-48.png",
        description: "Minivans handle like a car but with the space of a truck.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "minivan",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Pickup",
        title: "Pickups",
        icon: "body_styles/icons8-pickup-48.png",
        description: "Excellent utility with the ability to haul stuff in an open bed.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "pickup",
            },
          ],
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "truck",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Sedan",
        title: "Sedans",
        icon: "body_styles/icons8-sedan-48.png",
        description: "With 4 doors and a separate trunk.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "sedan",
            },
          ],
        ],
        enabled: false,
      },
      { id: "SUV",
        title: "SUVs",
        icon: "body_styles/icons8-suv-48.png",
        description: "SUVs usually have a commanding driving position and lots of space.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "suv",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Wagon",
        title: "Wagons",
        icon: "body_styles/icons8-wagon-48.png",
        description: "More space than a sedan without any of the draw backs of an SUV.",
        attributes: [
          [
            {
              path: "body_style_override",
              valueMode: "include",
              value: "wagon",
            },
          ],
        ],
        enabled: false,
      },
    ]
  },
  { // Geo Regions
    title: 'Regions',
    defaultExpanded: false,
    selection: 'any',
    filters: [
      { id: "USA",
        title: 'USA 🇺🇸',
        description: "Cars from American brands",
        attributes: [
          [
            {
              path: "geo_region",
              valueMode: "equal",
              value: "usa",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Europe",
        title: 'Europe 🇪🇺',
        description: "Cars from Eruopean brands",
        attributes: [
          [
            {
              path: "geo_region",
              valueMode: "equal",
              value: "eu",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Japan",
        title: 'Japan 🇯🇵',
        description: "Cars from Japanese brands",
        attributes: [
          [
            {
              path: "geo_region",
              valueMode: "equal",
              value: "jp",
            },
          ],
        ],
        enabled: false,
      },
      { id: "RestOfWorld",
      title: 'Other 🌏',
      description: "Cars from brands located in other regions",
      attributes: [
        [
          {
            path: "geo_region",
            valueMode: "equal",
            value: "other",
          },
        ],
      ],
      enabled: false,
    },
    ]
  },
  { // Makes
    title: 'Popular Brands',
    defaultExpanded: false,
    selection: 'any',
    filters: [
      { id: "Audi",
        title: 'Audi',
        icon: "logos/icons8-audi-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "audi",
            },
          ],
        ],
        enabled: false,
      },
      { id: "BMW",
        title: 'BMW',
        icon: "logos/icons8-bmw-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "bmw",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Chevrolet",
        title: 'Chevrolet',
        icon: "logos/icons8-chevrolet-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "chevrolet",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Ford",
        title: 'Ford',
        icon: "logos/icons8-ford-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "ford",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Honda",
        title: 'Honda',
        icon: "logos/icons8-honda-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "honda",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Hyundai",
        title: 'Hyundai',
        icon: "logos/icons8-hyundai-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "hyundai",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Kia",
        title: 'Kia',
        icon: "logos/icons8-kia-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "kia",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Mercedes-Benz",
        title: 'Mercedes-Benz',
        icon: "logos/icons8-mercedes-benz-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "mercedes-benz",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Nissan",
        title: 'Nissan',
        icon: "logos/icons8-nissan-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "nissan",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Subaru",
        title: 'Subaru',
        icon: "logos/icons8-subaru-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "subaru",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Tesla",
        title: 'Tesla',
        icon: "logos/icons8-tesla-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "tesla",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Toyota",
        title: 'Toyota',
        icon: "logos/icons8-toyota-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "toyota",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Volkswagen",
        title: 'Volkswagen',
        icon: "logos/icons8-volkswagen-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "volkswagen",
            },
          ],
        ],
        enabled: false,
      },
      { id: "Volvo",
        title: 'Volvo',
        icon: "logos/icons8-volvo-48.png",
        attributes: [
          [
            {
              path: "car.make",
              valueMode: "equal",
              value: "volvo",
            },
          ],
        ],
        enabled: false,
      },
    ]
  },
];

// Dump filter paths for data minification
// Take the output of this and apply it to the strigil consolidate_local_thecarconnection.py (at the top)
// That will generate a minified flatted parameter set which you can place in src/data/cars.json
// { // Uncomment this and reload the devloper page
//   var paths: any = {};
//   filterConfig.forEach((group: FilterGroup) => {
//     group.filters.forEach((filter: Filter) => {
//       filter.attributes.forEach((attributeSet: FilterAttributeTarget[]) => {
//         attributeSet.forEach((attribute: FilterAttributeTarget) => {
//           paths[attribute.path] = true;
//         });
//       });
//     });
//   });
//   console.log(JSON.stringify(paths));
// }


const FilterProvider: React.FC<React.ReactNode> = ({ children }) => {  

  const [filterGroups, setFilterGroups] = React.useState<FilterGroup[]>(filterConfig);
  
  const updateFilter = (id: string, enabled: boolean) => {
    filterGroups.filter((filterGroup: FilterGroup) => {
      filterGroup.filters.filter((filter: Filter) => {
        if (filter.id === id) {
          filter.enabled = enabled
          if (filter.enabled && filter.conflictsWithIds) {
            filter.conflictsWithIds.forEach((conflictFilter) => {
              updateFilter(conflictFilter, false);
            });
          }
          setFilterGroups([...filterGroups])
        }
      });
    });
  }

  return (
    <FilterContext.Provider value={{ filterGroups, updateFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
