import React, { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import FilterToggle from "./FilterToggle";
import { FilterContext } from "../../../context/filterContext";
import { Filter, FilterContextType, FilterGroup } from "../../../@types/filter";
import FilterGroupHeader from "./FilterGroupHeader";
import { Button } from "gatsby-theme-material-ui";


export const CarFilterSelectors:FC = ({ children }) => {
  const { filterGroups, updateFilter } = React.useContext(FilterContext) as FilterContextType;


  return (
    <Box>
      <Grid container
        direction={{xs: "column"}}
        // alignItems="center"
        spacing={1}
        justifyContent="space-around"
        padding={1}
        >

        <Grid item xs={12} sx={{ display: {xs: "block",  md: "none"} }} textAlign="center">
          Choose what's important to you and scroll down to see results ⬇️
        </Grid>

        <Grid item xs={12} sx={{ display: {xs: "none",  md: "block"} }} textAlign="center" margin={1}>
          Choose what's important to you and check the results ➡️
        </Grid>

        {filterGroups.map((filterGroup: FilterGroup) => (
          <Grid item key={filterGroup.title}>
            <Accordion defaultExpanded={filterGroup.defaultExpanded}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FilterGroupHeader filterGroup={filterGroup} />
              </AccordionSummary>
              <AccordionDetails>
                <Grid container
                  direction={{xs: "row", md:"column", lg: "row"}}
                  // alignItems="center"
                  spacing={1}
                  justifyContent="space-around"
                  padding={1}
                  >
                  {filterGroup.filters.map((filter: Filter) => (
                    <FilterToggle key={filter.title} updateFilter={updateFilter} filter={filter} />
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
        </Grid>
      ))}

      <Grid item margin={1} textAlign="center">
        <Typography variant="caption">
          Let us know if you have comments, questions or suggestions!<br />
          <a href="mailto:hello@goodcarsforme.com">hello@goodcarsforme.com</a>
        </Typography>
      </Grid>

    </Grid>
    </Box>
  );
};

export default CarFilterSelectors;
