import React from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";

import StandardFooter from "../components/StandardFooter"
import StandardHeader from "../components/StandardHeader";

import CarFilterExperience from "../components/carsite/CarFilterExperience";


const HomePage = () => {
  return (
    <Box>
      <Helmet title="Good Cars For Me" />

      <StandardHeader />
      
      <CarFilterExperience />

      <StandardFooter />
    </Box>
  );
};

export default HomePage;
