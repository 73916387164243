import React, { FC } from "react";
import { Grid, Typography } from "@mui/material";

import { FilterContext } from "../../../context/filterContext";
import { Filter, FilterAttributeTarget, FilterContextType, FilterGroup } from "../../../@types/filter";

import CarResult from "./CarResult";

import { filterConfig } from "../../../context/filterContext";

// Data Source: See Strigil -> consolidate_local_thecarconnection.py
const carData = require("../../../data/cars_flat.json");  // Pre-filtered car data. Smaller for prod.
//const carData = require("../../../data/cars_merged.json");  // Raw car data. Large and slow but all attributes are available for dev

// Initialize the car data with calculated attributes
{
  carData.forEach((car: any) => { // Iterate the cars
    if (car.filters === undefined) { // Hasn't been processed yet
      car.filters = []; // Create a filter Cache
      // Traverse all filters and cache values
      filterConfig.forEach((filterGroup: FilterGroup) => { // Iterate the filter groups
        filterGroup.filters.map((filter: Filter) => { // Iterate the filters in the group
          car.filters[filter.id] = false; // Default it to false
          filter.attributes.forEach((filterAttributes) => { // Iterate the filter attribute sets in the group
            var filterAttributeTargetTotalCount = 0;
            var filterAttributeTargetMatchCount = 0;

            filterAttributes.forEach((target: FilterAttributeTarget) => { // Iterate the filter attribute targets in the filter attribute set
              filterAttributeTargetTotalCount++;

              // Extract the leaf value
              var leaf: any;
              if (target.path in car) { // Static path exists, minified data
                leaf = car[target.path]; // Start the tree crawl
              } else { // Develop mode with raw data
                let pathParts = target.path.split("."); // Extract the path
                var leaf = car; // Start the tree crawl
                for (let value of pathParts) { // Iterate path parts...
                  if (value in leaf) { // Only dig in if the leaf exists
                    leaf = leaf[value]; // Focus on the new leaf for the next path part
                  } else { // Leaf doesn't exist
                    return; // Bail early, don't need to eval since we didn't get to the end
                  }
                };
              }

              // Evaluate the leaf
              if (typeof leaf === "string") {  // If we didn't get down to a string then we can't eval, it's not a match due to missing property
                switch(target.valueMode) {
                  case "equal":
                    if (leaf === target.value) { // Final leaf equals the target
                      filterAttributeTargetMatchCount++; // Filter match is true, increment the success count
                    }
                    break;
                  case "include":
                    if (leaf.includes(target.value)) { // Final leaf excludes the target
                      filterAttributeTargetMatchCount++; // Filter match is true, increment the success count
                    }
                    break;
                  case "exclude":
                    if (!leaf.includes(target.value)) { // Final leaf excludes the target
                      filterAttributeTargetMatchCount++; // Filter match is true, increment the success count
                    }
                    break;
                  case "greaterThan":
                    if (typeof leaf === "string") { // Must be a string because we're gonna manipulate it
                      leaf = leaf.split(" ")[0]; // Grab the first chunk incase it has a space and junk
                      leaf = leaf.replace(",", ""); // strip commas so it can be treated as a number
                      if (+leaf > +target.value) {
                        filterAttributeTargetMatchCount++; // Filter match is true, increment the success count
                      }
                    }
                    break;
                  case "lessThan":
                    if (typeof leaf === "string") {
                      leaf = leaf.split(" ")[0]; // Grab the first chunk incase it has a space and junk
                      leaf = leaf.replace(",", ""); // strip commas so it can be treated as a number
                      if (+leaf < +target.value) {
                        filterAttributeTargetMatchCount++; // Filter match is true, increment the success count
                      }
                    }
                    break;
                  }
                }
            });

            // Check the attribute target result
            if (filterAttributeTargetMatchCount == filterAttributeTargetTotalCount) { // All of the targets in the attribute set matched
              //console.log(car.car.model + " (" + filterGroup.title + ") matches " + filter.title + " attribute set");
              car.filters[filter.id] = true; // An attribute group was true, this filter is a match
              return; // Can bail on iteration since one target set match is a match for the attribute set
            }
          });
        });
      });

      // Flat file
      if ("car.make_name" in car) {
        // Construct a consistent attributes for the car
        car.title = `${car["car.make_name"]} ${car["car.model_name"]}`;
        car.make = car["car.make_name"];
        car.model = car["car.model_name"];
        // Cache dom element (after we know filters) so we only need to make them once
        car.domElement = <CarResult key={car["car.make_name"] + car["car.model_name"] + car["car.year"]} carData={car} />
      } else {
        // Construct a consistent title for the car
        car.title = `${car["car"]["make_name"]} ${car["car"]["model_name"]}`;
        car.make = car["car"]["make_name"];
        car.model = car["car"]["model_name"];
        // Cache dom element (after we know filters) so we only need to make them once
        car.domElement = <CarResult key={car["car"]["make_name"] + car["car"]["model_name"] + car["car"]["year"]} carData={car} />
        // Remove the base stuff to make more memory space
        // delete car.categories;
        // delete car.car;
      }
    }
  });
}

export const CarFilterResults:FC = ({ children }) => {
  const { filterGroups } = React.useContext(FilterContext) as FilterContextType;

  // Apply the filters to the car list
  const filteredCars = carData.filter((car: any) => { // Iterate the cars
    var filterGroupTotalCount = 0;
    var filterGroupMatchCount = 0;
    filterGroups.forEach((filterGroup: FilterGroup) => { // Iterate the filter groups
      filterGroupTotalCount++; // Increment total count of filters

      var filterTotalCount = 0;
      var filterMatchCount = 0;
      const activeFilters = filterGroup.filters.filter((filter: Filter) => {return filter.enabled}) // Limit to enabled filters in the group      
      activeFilters.map((filter: Filter) => { // Iterate the filters in the group
        filterTotalCount++;

        if (car.filters[filter.id]) { // Filter has a cached value of true
          filterMatchCount++; // This filter is a match
        }
      });

      // Check the filter group result
      switch(filterGroup.selection) {
        case "any":
          if (filterMatchCount > 0 || filterTotalCount == 0) { // Matched something in group, or there was nothing in group to match
            filterGroupMatchCount++; // A subset was true, this filter group is a match
          }
          break;

        case "all":
          if (filterMatchCount == filterTotalCount) { // Must match all selected filters in the group
            filterGroupMatchCount++; // A subset was true, this filter group is a match
          }
          break; 
      }
    });

    // All the groups were a match or innactive
    const isMatch = (filterGroupTotalCount > 0 && filterGroupMatchCount == filterGroupTotalCount);
    car.visible = isMatch;
    if (car.setVisible) {
      car.setVisible(isMatch);
    }
    return isMatch; // All the groups were a match or innactive
  });

  return (
    <Grid container direction="row">

      <Grid item xs={12} textAlign="center" paddingTop={1}>
        <Typography variant="h4">{filteredCars.length} Car{filteredCars.length == 1 ? "" : "s"}</Typography>
      </Grid>

      {carData.map((carData: any) => (
        carData.domElement
      ))}

      <Grid item xs={12} textAlign="center" padding={1} margin={3} sx={{
        display: filteredCars.length == 0 ? "block" : "none"
      }}>
        <Typography variant="h3" component="div">
          Oh No❗️
        </Typography>
        <Typography variant="body1" component="div">
          We couldn't find any cars that match your exact criteria 😔
        </Typography>
        <Typography variant="body1" component="div">
          Try adjusting your selected options ✅
        </Typography>
      </Grid>

    </Grid>
  );
};

export default CarFilterResults;
