import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { FilterGroup } from "../../../@types/filter";


type FilterGroupHeaderProps = {
  filterGroup: FilterGroup
}

export const FilterGroupHeader:FC<FilterGroupHeaderProps> = ({ filterGroup }) => {
  return (
    <Box>
        <Typography variant="h4" component="div">
          {filterGroup.title}
          {/* <Typography variant="caption" component="span" color="#666">
            &nbsp;({filterGroup.selection})
          </Typography> */}
        </Typography>
    </Box>
  );
};

export default FilterGroupHeader;
