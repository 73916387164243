import React, { FC } from "react";
import { Box, Tooltip } from "@mui/material";

export interface DetailIconConfig {
  icon: String,
  label: String,
}

type DetailIconProps = {
  icon: DetailIconConfig
}

// NOTE: The trailing space before </Box> is needed to space the icons nicely
export const DetailIcon:FC<DetailIconProps> = ({ icon }) => {
  return (
    <Tooltip title={icon.label} arrow disableInteractive>
      <Box sx={{ 
        display: "inline",
        cursor: "default",
      }}>
        {icon.icon} </Box>
    </Tooltip>
  );
};

export default DetailIcon;
