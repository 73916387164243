import React, { FC } from "react";
import { Grid, ToggleButton, Tooltip } from "@mui/material";
import { Filter } from "../../../@types/filter";


type FilterToggleProps = {
  filter: Filter
  updateFilter: (title: string, enabled: boolean) => void
}

export const FilterToggle:FC<FilterToggleProps> = ({ filter, updateFilter }) => {
  return (
    <Grid item xs={6} sm={4} md={6} lg={6}>
      <Tooltip title={filter.description || ""} arrow disableInteractive>
        <ToggleButton 
        color="primary"
        fullWidth={true}
        value={filter.enabled}
        selected={filter.enabled}
        sx={{
          backgroundColor: "rgb(250, 250, 250)"
        }}
        onChange={() => {
          updateFilter(filter.id, !filter.enabled);
        }}
        >
          {filter.title} {filter.icon ? <img src={filter.icon} alt={filter.title + " Icon"} height={24} style={{
            paddingLeft: 4,
          }} /> : null}
        </ToggleButton>
      </Tooltip>
    </Grid>
  );
};

export default FilterToggle;
